import "./styles.scss"
// Change the file to be treated as a module by adding an export statement
export {};
const apiUrl = "https://api.sofiabot.ai";
// Declacre global variables for TypeScript
declare global {
    interface Window {
        FS?: {
            event: (eventName: string, eventData: object) => void;
        };
        gtag?: (command: string, action: string, params: object) => void;
    }
}

// Theme switching functionality
function initThemeSwitch() {
    const toggleSwitch = document.querySelector('#checkbox') as HTMLInputElement;
    const currentTheme = localStorage.getItem('theme');

    if (currentTheme) {
        toggleSwitch.checked = false;
        if (currentTheme === 'dark') {
            toggleSwitch.checked = true;
            toggleSwitch.click();
        }
    }

    function switchTheme(e: Event) {
        const target = e.target as HTMLInputElement;
        if (target.checked) {
            document.documentElement.querySelector('body')?.classList.add('dark-theme');
            document.documentElement.querySelector('body')?.classList.remove('light-theme');

            document.documentElement.querySelector('.navbar-nav')?.classList.add('navbar-dark');
            document.documentElement.querySelector('.navbar-nav')?.classList.remove('navbar-light');

            document.documentElement.querySelector('.navbar-brand')?.classList.add('navbar-dark');
            document.documentElement.querySelector('.navbar-brand')?.classList.remove('navbar-light');

            document.documentElement.querySelectorAll('.text-muted, .carousel-item p').forEach(el => {
                el.classList.remove('text-light');
                el.classList.add('text-dark');
            });
            localStorage.setItem('theme', 'dark');
        } else {
            document.documentElement.querySelector('body')?.classList.add('light-theme');
            document.documentElement.querySelector('body')?.classList.remove('dark-theme');
            document.documentElement.querySelector('.navbar-nav')?.classList.add('navbar-light');
            document.documentElement.querySelector('.navbar-nav')?.classList.remove('navbar-dark');
            document.documentElement.querySelector('.navbar-brand')?.classList.remove('navbar-dark');
            document.documentElement.querySelector('.navbar-brand')?.classList.add('navbar-light');

            document.documentElement.querySelectorAll('.text-muted, .carousel-item p').forEach(el => {
                el.classList.remove('text-dark');
                el.classList.add('text-light');
            });
            localStorage.setItem('theme', 'light');
        }
    }

    toggleSwitch.addEventListener('change', switchTheme, false);
}

function initChat() {
    const chatForm = document.getElementById('chat-form') as HTMLFormElement;
    const userInput = document.getElementById('user-input') as HTMLInputElement;
    const chatMessages = document.getElementById('chat-messages') as HTMLDivElement;
    const conversationStarters = document.getElementById('conversation-starters') as HTMLDivElement;
    const resetButtonContainer = document.getElementById('reset-button-container') as HTMLDivElement;
    // Create observer to watch chat-messages
    const observer = new MutationObserver(function(mutations) {
        if (chatMessages.children.length > 0) {
            conversationStarters.style.display = 'none';
            resetButtonContainer.style.display = 'block';
        } else {
            conversationStarters.style.display = 'block';
            resetButtonContainer.style.display = 'none';
        }
    });

    // Start observing chat-messages for changes
    observer.observe(chatMessages, {
        childList: true
    });

    // suggestions
    const suggestions = [
        "How can you help me manage my daily tasks?",
        "What productivity features do you offer?",
        "Can you help me schedule meetings efficiently?",
        "How do you assist with email management?",
        "What are your document summarization capabilities?",
        "Can you help me create project timelines?",
        "How do you handle calendar scheduling?",
        "Can you help me prioritize my workload?",
        "What research assistance can you provide?",
        "How do you help with time management?",
        "Can you help me track project deadlines?",
        "How do you assist with note-taking?",
        "What reminder features do you have?",
        "Can you help me organize my files?",
        "How do you help with meeting preparation?"
    ];

    const suggestionButtons = document.getElementById('suggestion-buttons');
    const randomSuggestions = suggestions.sort(() => 0.5 - Math.random()).slice(0, 3);
    randomSuggestions.forEach(suggestion => {
        const button = document.createElement('button');
        button.className = 'btn btn-outline-secondary btn-sm suggestion m-1 d-block w-100 mb-2';
        button.onclick = function() {
            userInput.value = (this as HTMLButtonElement).textContent || '';
            chatForm.dispatchEvent(new Event('submit'));
        };
        button.textContent = suggestion;
        suggestionButtons?.appendChild(button);
    });

    chatForm.addEventListener('submit', function(e: Event) {
        e.preventDefault();
        const submitButton = document.getElementById('submit-button') as HTMLButtonElement;
        // Limit sending to max 5 messages per conversation
        const messageCount = chatMessages.getElementsByClassName('message').length;
        if (messageCount >= 10) { // 10 because it includes both user and AI messages
            alert('You have reached the maximum number of messages for this conversation.');
            submitButton.disabled = true;
            e.preventDefault();
            return;
        }
        // Simple rate limiting
        let attempts = 0;
        const maxAttempts = 10;
        const baseDelay = 500;

        submitButton.disabled = true;
        const delay = attempts < maxAttempts ? baseDelay : baseDelay * Math.pow(2, attempts - maxAttempts);
        setTimeout(() => {
            submitButton.disabled = false;
            attempts++;
        }, delay);

        // Basic input sanitization
        const sanitizedInput = userInput.value.replace(/<[^>]*>?/gm, '');

        // Check for spam-like content
        if (sanitizedInput.length > 500 || sanitizedInput.length < 2 ||
            /\b(viagra|cialis|buy|cheap|free|offer|discount|limited time)\b/i.test(sanitizedInput) ||
            /(https?:\/\/[^\s]+)/g.test(sanitizedInput) ||
            /[^\x00-\x7F]+/.test(sanitizedInput) ||
            sanitizedInput.split(' ').some(word => word.length > 20)) {
            alert('Your message was flagged as potential spam. Please try again.');
            submitButton.disabled = true;
            e.preventDefault();
            return;
        }

        // If all checks pass, you can proceed with sending the message
        console.log('Sending message:', sanitizedInput);
        userInput.value = sanitizedInput;

        const message = userInput.value.trim();
        if (message) {
            addMessage('You', message);
            let aiResponseElement = addMessage('Sofia🤖', '<div class="spinner-grow spinner-grow-sm text-secondary" role="status"><span class="visually-hidden">Loading...</span></div>');
            sendMessageToAnalytics(message);
            fetch(apiUrl + '/guest/chat/events', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'accept': '*/*',
                        'accept-language': 'en-US,en;q=0.9',
                        'content-type': 'application/json',
                        'X-CSRF-Token': window.localStorage.getItem('csrf-token') || '',
                    },
                    body: JSON.stringify({
                        message: message,
                        model: "llama3-8b",
                        conversationID: conversationID,
                        profileID: 0
                    })
                })
                .then(response => {
                    const reader = response.body!.getReader();
                    const decoder = new TextDecoder();
                    updateMessage(aiResponseElement, "", true);
                    function readStream(): Promise<void> {
                        return reader.read().then(({
                            done,
                            value
                        }) => {
                            if (done) {
                                return;
                            }
                            const chunk = decoder.decode(value, {
                                stream: true
                            });

                            const chunks = chunk.split('\n\n');
                            let delay = 0;
                            chunks.forEach((chunk) => {
                                if (!chunk) {
                                    return;
                                }
                                if (chunk.includes("[DONE]")) {
                                    setTimeout(() => {
                                        sendMessageToAnalytics(aiResponseElement.textContent!);
                                    }, delay);
                                    return;
                                }
                                const data = JSON.parse(chunk.trim().replace("data: ", ""));
                                if (data.content) {
                                    setTimeout(() => {
                                        updateMessage(aiResponseElement, data.content);
                                    }, delay);
                                    delay += Math.floor(Math.random() * (80 - 40 + 1)) + 40; // Add random delay between 40ms and 80ms for each chunk
                                } else if (data.id) {
                                    conversationID = data.id;
                                }
                            })

                            return readStream();
                        });
                    }

                    return readStream();
                })
                .catch(error => {
                    console.error('Error:', error);
                    updateMessage(aiResponseElement, 'Sorry, I encountered an error. Please try again.', true);
                });
            userInput.value = '';
        }
    });

    function addMessage(sender: string, message: string): HTMLSpanElement {
        const messageElement = document.createElement('div');
        messageElement.className = 'mb-2';
        messageElement.innerHTML = `<strong>${sender}:</strong> <span class="message-content">${message}</span>`;
        chatMessages.appendChild(messageElement);
        chatMessages.scrollTop = chatMessages.scrollHeight;
        return messageElement.querySelector('.message-content') as HTMLSpanElement;
    }

    function updateMessage(element: HTMLSpanElement, newContent: string, reset: boolean = false) {
        if (reset) {
            element.textContent = "";
        }
        element.textContent += newContent;
        chatMessages.scrollTop = chatMessages.scrollHeight;
    }
}

// Load more use cases functionality
function initUseCases() {
    const loadMoreButton = document.getElementById('loadMoreCases') as HTMLButtonElement;
    const additionalCases = document.getElementById('additionalCases') as HTMLDivElement;

    loadMoreButton.addEventListener('click', function() {
        additionalCases.style.display = 'flex';
        this.style.display = 'none';
    });
}

// Newsletter subscription functionality
function initSubscription() {
    const subscribeForm = document.getElementById('subscribeForm') as HTMLFormElement;
    const subscribeEmail = document.getElementById('subscribeEmail') as HTMLInputElement;
    const subscribeMessage = document.getElementById('subscribeMessage') as HTMLDivElement;

    subscribeForm.addEventListener('submit', function(e: Event) {
        e.preventDefault();
        const email = subscribeEmail.value;

        fetch('https://us-central1-chat-386310.cloudfunctions.net/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    subscribeMessage.innerHTML = 'Thank you for subscribing!';
                    subscribeMessage.style.color = 'green';
                } else {
                    subscribeMessage.innerHTML = 'An error occurred. Please try again.';
                    subscribeMessage.style.color = 'red';
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                subscribeMessage.innerHTML = 'An error occurred. Please try again.';
                subscribeMessage.style.color = 'red';
            });
    });
}

// Smooth scrolling functionality
function initScroll() {
    const navLinks = document.querySelectorAll('.navbar-nav .nav-link, footer .list-unstyled a[href^="#"]');
    const headerHeight = (document.querySelector('.navbar') as HTMLElement).offsetHeight;

    navLinks.forEach(link => {
        link.addEventListener('click', function(this: HTMLAnchorElement, e: Event) {
            e.preventDefault();
            const targetId = this.getAttribute('href')!.substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - headerHeight;
                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }
        });
    });

    // Autofocus on "Try It Now" input when navbar button is clicked
    const tryItNowButton = document.querySelector('.navbar-nav .nav-item a.try-it') as HTMLElement;
    const userInput = document.getElementById('user-input') as HTMLInputElement;

    if (tryItNowButton && userInput) {
        tryItNowButton.addEventListener('click', function(e) {
            e.preventDefault();
            setTimeout(() => {
                userInput.focus();
            }, 100); // Small delay to ensure smooth scrolling completes
        });
    }
}

// cookie consent
function initializeCookieConsent() {
  const cookieConsentBanner = document.getElementById('cookie-consent-banner') as HTMLElement;
  const acceptCookiesBtn = document.getElementById('accept-cookies') as HTMLElement;
  const rejectCookiesBtn = document.getElementById('reject-cookies') as HTMLElement;

  function setCookie(name: string, value: string, days: number): void {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function showCookieConsentBanner(): void {
    cookieConsentBanner.style.display = 'block';
  }

  function hideCookieConsentBanner(): void {
    cookieConsentBanner.style.display = 'none';
  }

  function handleCookieConsent(): void {
    if (getCookie('cookie_consent') === null) {
      showCookieConsentBanner();
    }
  }

  acceptCookiesBtn.addEventListener('click', function () {
    setCookie('cookie_consent', 'accepted', 365);
    hideCookieConsentBanner();
  });

  rejectCookiesBtn.addEventListener('click', function () {
    setCookie('cookie_consent', 'rejected', 365);
    hideCookieConsentBanner();
  });

  handleCookieConsent();
}

// Event listener for reset button
function initResetButton() {
    // Function to reset conversation
    const resetButton = document.getElementById('reset-conversation') as HTMLButtonElement;
    if (resetButton) {
        resetButton.addEventListener('click', function() {
            const chatMessages = document.getElementById('chat-messages') as HTMLDivElement;
            const userInput = document.getElementById('user-input') as HTMLInputElement;

            chatMessages.innerHTML = '';
            userInput.value = '';
            conversationID = "";
        });
    }
}

// Function to send message to analytics
function sendMessageToAnalytics(message: string) {
    // Send message to Fullstory
    // @ts-ignore
    if (window.FS && typeof window.FS.event === 'function') {
        // @ts-ignore
        window.FS.event('Chat Message Sent', {
            message: message,
            timestamp: new Date().toISOString()
        });
    }

    // Send message to Google Analytics
    // @ts-ignore
    if (window.gtag && typeof window.gtag === 'function') {
        // @ts-ignore
        window?.gtag('event', 'Chat Message Sent', {
            'event_category': 'Landing Chat',
            'event_label': message,
            'value': 1
        });
    }
}
let conversationID : string = "";
// Chat functionality

function getCsrfToken() {
    fetch(apiUrl + '/health', {
        credentials: 'include', // Include cookies in the request
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const csrfToken = response.headers.get('x-csrf-token');
        if (csrfToken) {
            window.localStorage.setItem('csrf-token', csrfToken);
        }
        return response.json();
    })
    .then(data => {
        console.log('Branding data:', data);
    })
    .catch(error => {
        console.error('Error fetching branding:', error);
    });
}


document.addEventListener('DOMContentLoaded', function() {
    getCsrfToken();
    
    initScroll();
    initResetButton();
    initializeCookieConsent();
    initSubscription();
    initUseCases();
    initChat();
    initThemeSwitch();
});